<template>
    <div style="padding: 20px;">
        <el-row ref="search_form" style="display: flex;">
            <el-col :span="24">
                <div class="search-box" >
                    <el-form :inline="true" size="medium" :model="searchForm">
                        <!-- <el-form-item>
                            <el-button size="mini" type="primary" @click="search">刷新</el-button>
                        </el-form-item> -->
                        <!-- <el-form-item>
                            <el-button size="mini" type="primary" @click="showAddDialogForm">新增嘉宾</el-button>
                        </el-form-item> -->
                        <!-- <el-form-item label="" style="display: none;">
                            <el-select v-model="searchForm.userTypeId" size="mini" placeholder="请选择嘉宾类型" multiple
                                collapse-tags clearable>
                                <el-option v-for="item in userTypeList" :key="item.id" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <el-form-item label="">
                            <el-select v-model="searchForm.hyId" size="mini" placeholder="请选择会议" @change="hh($event)">
                                <el-option v-for="item in dddd" :key="item.id" :label="item.title"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    
                </div>
            </el-col>
            <div>
              <el-button style="margin-left: 10px;" size="small" type="primary" @click="sctp">上传图片</el-button>
            </div>
        </el-row>
        <div style="margin-bottom: 10px;">该板块上传的图片：</div>
<div style="display: flex;">
    <!-- :action="uploadActionUrl" -->
    <el-upload
        multiple
  action="https://upload.qiniup.com"
  list-type="picture-card"
  :on-preview="handlePictureCardPreview"
  ref="upload"
  :on-success="success"
  :on-remove="remove"
  :data="QiniuData"
  :before-upload="beforeUpload"
  >
  <i class="el-icon-plus"></i>
</el-upload>

</div>

<el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl1" alt="">
</el-dialog>

<div style="margin-top: 10px;">该版块已有的图片:</div>
<div class="container">
    <div class="head"></div>
    <div class="body">
        <div style="display: flex; flex-wrap: wrap; text-align: center;">
        <div class="xcxc" v-for="(item,index) in chakan1"  >
        <img class="zzzz" :src="item.img1" alt="">
        <div class="cccc">
            <p style="position: absolute; left: 50%; top: 50%; transform: translateX(-50%);">
                <i class="el-icon-zoom-in" style="cursor: pointer; color:#fff; font-size: 18px; margin-right: 20px;" @click="fangda(item)"></i>
        <i class="el-icon-delete" style="color: #fff; font-size: 18px; margin-left: 20px; cursor: pointer;" @click="del(item,index)"></i>
            </p>
        </div>
        </div>
   </div>
    </div>
 <div class="footer"> </div>
</div>

</div>
</template>
  
<script>
//   import * as qiniu from 'qiniu-js'
import { tok } from '../../api//imagelibrary';
import { moban } from '../../api//imagelibrary';
import { delt } from '../../api//imagelibrary';
import {chakan} from '../../api/imagelibrary';
import {shangchuan} from '../../api/imagelibrary';
import { validPhone } from "@/common/utils/validate.js";
import util from "@/common/utils/util.js";
import { getUserList, addUser, updateUser, deleteUser } from "@/api/user";
import { getUserTypeList } from "../../api/user_type";
import { getYycompanyList } from "../../api/yy_company";
import { getFrontUserList } from "../../api/front_user";
import {
    getMyParticipateList,
    addMyParticipate,
    updateMyParticipate,
    deleteParticipate,
    deleteMyParticipate,
    updateDLInfo,
    updateSortBy,
    shenhe,
    getParticipateGB,
    getParticipateSameNameJB,
} from "@/api/participate";
import { getPlateList, getPlateListWithGroup } from "../../api/plate";
import { getSystemSet } from "../../api/system_setting";
import { getHotelList } from "../../api/hotel";
import { getAllUserList } from "../../api/user";
import json from "@/assets/address.json";
import {
    getInvitationCode,
    sendYYSMS,
    sendYYEmail,
    sendYYSMSBatch,
    sendYYEmailBatch,
} from "../../api/invitation";
import axios from "axios";
import { getToken } from "../../common/utils/auth";
import { remove } from 'js-cookie';

export default {
    name: "myParticipateManage",
    comments: {
        // VueCanvasPoster
    },
    data() {
        return {
            QiniuData: {    //这里是直接绑定data的值
        key: "", //图片名字处理
        token: "", //七牛云token
        data: {},
        bucket: " "
            },
            ff:'',
            dddd:[],
            dialogImageUrl1:"",
            chakan1:[],
            zzzz:[],
            pkid:'',
            zhaopian:'',
            fileList: [],
            domain: 'https://cdn.ybh.gengduoke.com/',
            dialogImageUrl: '',
            dialogVisible: false,
            total: 0,
            pageSize: 20,
            currentPage: 1,
            tableData: [],
            tableHeight: 200,
            editFormVisible: false,
            loading: false,
            editForm: {
                id: 0,
                frontUserId: "",
                handlerId: "",
                username: "",
                phone: "",
                phoneType: "",
                email: "",
                gender: "",
                company: "",
                yslingdao: "",
                ysdesc: "",
                country: "中国",
                idcard: "",
                idCardType: 0,
                sheng: "",
                shi: "",
                xian: "",
                hyIds: [],
                hyId: "",
                userTypeId: "",
                yyCompanyId: "",
                workGroup: "",
                qy: "",
                note2: "",
                note: "",
                status: "",
                yq: "",
                yyType: "",
                faceImg: "",
                ppid: "",
                qyOther: "",
                post: "",
            },
            gbList: [],
            editRules: {
                username: [
                    { required: true, message: "请输入参会人员姓名", trigger: "blur" },
                ],
                gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
                company: [
                    { required: true, message: "请输入参会人员单位", trigger: "blur" },
                ],
                post: [
                    { required: true, message: "请输入参会人员职务", trigger: "blur" },
                ],
                country: [{ required: true, message: "请输入国籍", trigger: "blur" }],
                sheng: [{ required: true, message: "请选择地址", trigger: "blur" }],
                hyIds: [
                    { required: true, message: "请选择邀请参加会议", trigger: "blur" },
                ],
                userTypeId: [
                    { required: true, message: "请选择嘉宾类型", trigger: "blur" },
                ],
                qy: [{ required: true, message: "请选择付费方式", trigger: "blur" }],
                status: [
                    { required: true, message: "请选择是否参会", trigger: "blur" },
                ],
                // yq: [{ required: true, message:"请选择7天内是否到过疫情地区", trigger: 'blur'}],
                yyType: [
                    { required: true, message: "请选择邀约类型", trigger: "blur" },
                ],
                phone: [
                    // { required: true, message:"请输入手机号", trigger: 'blur'},
                    {
                        validator: validPhone,
                        message: "手机号码格式错误",
                        trigger: "blur",
                    },
                ],
                email: [
                    { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur"] },
                ],
            },
            loadingDiv: null,
            searchForm: {
                cardId: "",
                phone: "",
                username: "",
                post: "",
                userTypeId: "",
                company: "",
                hyId: "",
                status: "",
                yyCompanyId: "",
                workGroup: "",
                hotelId: "",
                country: "",
                qy: "",
                handlerId: "",
                createUserId: "",
                xingchengZt: "",
                arriveTime1: "",
                arriveTime2: "",
                backTime1: "",
                backTime2: "",
                updateTime1: "",
                updateTime2: "",
                createTime1: "",
                createTime2: "",
                userTypeIdList: [1, 2, 3, 4, 5, 6],
                xczt: "",
            },

            date: {
                date1: "",
                date2: "",
                date3: "",
                date4: "",
            },
            plateList: [],
            statusList: [
                // 状态  -1取消  1通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾
                { value: "", label: "请选择参会状态" },
                { value: 99, label: "审核+确定" },
                { value: 0, label: "审核中" },
                { value: 1, label: "审核通过" },
                { value: 3, label: "确定参加" },
                { value: 2, label: "暂不确定" },
                { value: -1, label: "取消参加" },
                { value: -2, label: "嘉宾拒绝" },
            ],
            yyCompanyList: [],
            workGroupList: [],
            hotelList: [],
            countryList: [
                { value: "", label: "请选择国家" },
                { value: "中国", label: "中国" },
                { value: "非中国", label: "非中国" },
            ],
            qyList: [
                { value: "", label: "请选择付费方式" },
                { value: "全部承担", label: "全部承担" },
                { value: "承担住宿餐饮", label: "承担住宿餐饮" },
                { value: "仅餐饮", label: "仅餐饮" },
                { value: "全自费", label: "全自费" },
                { value: "其他", label: "其他" },
                // {value: '食宿补差', label: '食宿补差'},
                // {value: '住宿补差', label: '住宿补差'},
            ],
            handlerUserList: [],
            userList: [],
            xingchengZtList: [
                { value: "", label: "请选择行程状态" },
                { value: "未到", label: "未到" },
                { value: "已接到", label: "已接到" },
                { value: "到星光岛", label: "到星光岛" },
                { value: "入住酒店", label: "入住酒店" },
                { value: "已入场", label: "已入场" },
                { value: "离店", label: "离店" },
                { value: "离青", label: "离青" },
            ],
            userTypeList: [1, 2, 3, 4, 5, 6],
            address: [],
            selectValue: "",
            defultProp: {
                label: "name",
                value: "name",
                children: "sub",
            },
            isIndeterminate: false,
            checkAll: false,
            frontUserList: [],

            genPoster: false,
            yyhDialogVisible: false,
            genImgTitle: "",
            yyhImg: "",
            imgQrcodeContent: "",
            imgUserName: "",
            genYYHRow: null,
            painting: {
                width: `1080px`,
                height: `1890px`,
                background: "#f4f5f7",
                views: {},
            },
            yyInfoRow: "",
            yyInfoCode: "",
            yyInfoSMS: "",
            XCXScanTime: "",
            H5ScanTime: "",
            yyInfoSendStatus: "",
            plateWithGroupList: [],

            editDLFormVisible: false,
            editDLLoading: false,
            editDLForm: {
                id: "",
                pid: "",
                username: "",
                phone: "",
                gender: "",

                esArriveTime: "", // 预计抵青时间
                arriveTime: "", // 抵青时间

                arriveMode: "", // 到达方式 '飞机', '高铁', '自驾'
                carnum: "", // 车牌号

                frequency: "", // 班次
                arriveStation: "", // 抵青接站口

                backTime: "", // 离青时间

                backMode: "", // 离青方式
                backCarnum: "", // 离青车牌号

                backFrequency: "", // 离青班次
                lkdd: "", // 离青送站口

                dqqy: "", // 当前权益

                hotelId: "", // 分配酒店
                eatHotelId: "", // 分配吃饭地点

                jdNote: "", // 酒店床位备注

                xingchengZt: "", // 行程状态
                xingchengZtCurrent: "", // 修改之前行程状态

                dlNote: "", // 抵离备注
            },
            editDLRules: {},
            addShowFlag: false,

            dlDate: {
                date1: "",
                date2: "",
                date3: "",
            },
            jzkList: [
                // 接/送站口
                { label: "胶东国际机场", value: "胶东国际机场" },
                { label: "青岛北站", value: "青岛北站" },
                { label: "青岛站", value: "青岛站" },
                { label: "青岛西站", value: "青岛西站" },
            ],

            editSortBy: {
                sortByEditValue: "",
                pid: "",
            },

            excelImp: {
                dialogVisible: false,
                loading: false,
                fileList: [],
                action: "",
                loadingDiv: "",
            },

            baseUrl: "",
            uploadActionUrl: "",
            avatarUploadLoadingDiv: "",

            yyCompanyName: "",

            tabsActiveName: "",
        };
    },
    watch: {
        "editForm.id": function (val) {
            if (this.editForm.id == 0) {
                this.editRules["password"] = [
                    {
                        required: true,
                        min: 6,
                        message: "密码不能少于6个字符",
                        trigger: "blur",
                    },
                ];
            } else {
                this.editRules["password"] = [
                    {
                        required: false,
                        min: 6,
                        message: "密码不能少于6个字符",
                        trigger: "blur",
                    },
                ];
            }
        },
    },
    mounted() {

        tok()
        .then((res) => {
            console.log("大大方方的")
            console.log(res.data)
            this.QiniuData.token = res.data;
        })
        .catch((err) => {
            console.log(err)
        })

        moban()
        .then((res) => {
            console.log('sd12345sddssdsd')
            console.log(res.data);
            this.dddd = res.data
        })
        .catch((err) => {
            console.log(err)
        })
        // for(let i=0;i<100;i++){
        //   this.editSortBy.v["v"+i] = true;
        // }

        // this.getDataList(this.currentPage);

        let offsetHeight = this.$refs.search_form.$el.offsetHeight;
        if (!offsetHeight) {
            offsetHeight = 0;
        }

        this.$nextTick(() => {
            this.tableHeight =
                document.documentElement.clientHeight - 150 - offsetHeight;
        });
        window.onresize = () => {
            this.$nextTick(() => {
                this.tableHeight =
                    document.documentElement.clientHeight - 150 - offsetHeight;
            });
        };

        this.address = json;

        this.init();

        let t = getToken();
        this.excelImp.action =
            this.$adminApiUrl + "/participate/excel-imp?userType=0&_t=" + t;
        this.baseUrl = this.$baseUrl;
          this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
          console.log( this.uploadActionUrl ,2222);
    },
    methods: {
     
        beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 16.3;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 16MB!");
        return false
      }{
        let suffix = file.name;
       let  key = `upload_pic_${new Date().getTime()}_${file.name}`
      this.QiniuData.key = key;
      return this.QiniuData;
      }
       
    },
        fangda(itm) {
            console.log(itm.img)
            this.dialogImageUrl1 = itm.img;
        this.dialogVisible = true;
        },
        handlePictureCardPreview(file) {
        this.dialogImageUrl1 = file.url;
        this.dialogVisible = true;
      },
        del(it,ine) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            delt(it.id)
                .then((res) => {
                    console.log(res);
                    this.chakan1.forEach((item,index) => {
                         if( index == ine) {
                             this.chakan1.splice(index, 1)
                           }
                    })
                    this.$message({
                        message: `${res.msg}`,
                      type: 'success'
                      });
                })
                .catch((err) => {
                    console.log(err)
                })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
           
        },
        remove(file, fileList) {
        //   this.zzzz = this.zzzz.filter((item) => {
        //     item.key != file.response.key
        //   })
       this.zzzz = this.zzzz.filter(item => item != file.response.key)
          console.log(this.zzzz)
        },
        sctp() {
            console.log(this.ff)
            if(this.pkid == '') {
                this.$message.error('请选择会议');
                return;
            }
            if(this.zzzz == '') {
                this.$message.error('请上传图片');
                return;
            }
            shangchuan({ plateId: this.pkid, urls:this.zzzz})
            .then((res) => {
                console.log('sdsdsxccccc')
                console.log(res)
                this.$message({
                        message: '上传成功',
                      type: 'success'
                      });
               this.zzzz = [];
               this.$refs.upload.clearFiles();
               chakan(this.ff)
            .then((res) => {
                this.chakan1 = res.data;
            })
            .catch((err) => {
                console.log(err)
            })
            })
            .catch((err) => {
                console.log(err)
            })
        },
        success(file, fileList) {
            this.fileList.push(fileList)
            // let cc = []
            // this.fileList.forEach( item => {
            //     console.log(item)
            //     cc.push(item.response.data)
            // });
            // this.zzzz = cc;
            this.zhaopian = fileList.response.data;
            // let xx =''
            //  xx = "https://cdn.ybh.gengduoke.com/" + file.key;
            //  this.zzzz.push({img:xx, key:file.key})
            this.zzzz.push(file.key)
             console.log("东方饭店发的奋斗奋斗放大放大放大")
             console.log(this.zzzz)
        },
        // handlePictureCardPreview(file) {
        //     console.log(file)
        // },
        // handleChange(fileList) {
        //     this.fileList.push(fileList)
        //     console.log('ssddssddsdsdsdsds')
        //     console.log(this.fileList)
        //     this.fileList.forEach((item) => {

        //     })
        // },

        // uploadForm() {
        //     // this.$refs.upload.submit()
        //     if (this.fileList.length === 0) {
        //         this.$message.warning('请选取文件')
        //         return
        //     }
        //     const formData = new FormData()
        //     // 因为要传一个文件数组过去，所以要循环append
        //     formData.append('userId', this.userId) // 自定义参数

        //     console.log('eereererererereres')
        //     console.log(formData)
        //     let t = getToken();

        //     //   this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
        //     this.fileList.forEach((item) => {
        //         console.log(item)
        //         console.log(t)
        //         this.$refs.upload.action = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
        //     })

        //     // 清空图片列表（一定要清空，否则上传成功后还是会调用handleChange（）函数，上传成功后列表中还存在图片）
        //     //   this.fileList = []
        // },


        // handleChange(fileList) {
        //     this.fileList.push(fileList)
        //     console.log('ssddssddsdsdsdsds')
        //     console.log(this.fileList)
        //     this.fileList.forEach((item) => {

        //     })
        // },
        // ggg(file) {
        //     console.log(file)
        // },
        // upqiniu(paiam) {
        //     console.log(paiam)
        //     console.log(file)
        // },
        // errorone(file, fileList) {
        //     console.log('失败')
        //     console.log(file);
        //     console.log(fileList);
        // },
        // successone(file, fileList) {
        //     console.log(pictureList)
        //     console.log("成功")
        //     console.log(file)
        //     console.log(fileList)
        // },
        // handleRemove(file, fileList) {
        //     console.log('删除')
        //     console.log(file, fileList);
        // },
        // handlePictureCardPreview(file) {
        //     console.log('点击文件列表中已上传的文件时的钩子')
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // },
        hh(id) {
            console.log(id)
            this.ff = id;
            let gg = this.plateList.find((item) => {
                return item.id == id
            })
            this.pkid = id
            console.log(gg)
            chakan(id)
            .then((res) => {
                this.chakan1 = res.data;
            })
            .catch((err) => {
                console.log(err)
            })
        },
        columnStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1 || columnIndex == 2 || columnIndex == 3) {
                return "background:	#FFF8DC;";
            }
        },
        checkSameName(e) {
            if (util.isNotEmpty(this.editForm.username)) {
                getParticipateSameNameJB({
                    id: this.editForm.id,
                    username: this.editForm.username,
                })
                    .then((res) => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                        } else if (res.code == 2) {
                            this.$alert(res.msg, {
                                dangerouslyUseHTMLString: true,
                                type: "warning",
                                showClose: false,
                            });
                        }
                    })
                    .catch((err) => { });
            }
        },

        fmtSx(row) {
            if (util.isEmpty(row.sxList)) {
                return "";
            }

            let sxStr = "";
            let sxList = row.sxList;
            for (let i in sxList) {
                sxStr += sxList[i].username + "(" + sxList[i].phone + ")<br />";
            }
            console.log(sxStr);
            return sxStr;
        },
        getParticipateGB() {
            getParticipateGB({ my: true })
                .then((res) => {
                    if (res && res.code == 1) {
                        console.log(res.data);
                        this.gbList = res.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onUserTypeChange(e) {
            if (e == 1 || e == 2) {
                this.editForm.qy = "仅餐饮";
            }
            if (e == 3 || e == 4) {
                this.editForm.qy = "全部承担";
            }

            if (e == 5) {
                this.editForm.hyIds = [1];
            }
        },
        handleAvatarSuccess(res, file, fileList) {
            this.avatarUploadLoadingDiv.close();
            if (res.code == 1) {
                // this.$message({
                //   message: '文件上传成功',
                //   type: 'success'
                // });
                this.editForm.faceImg = res.data;
                return;
            } else {
                this.$message({
                    message: res.msg,
                    type: "warning",
                });
            }
        },
        beforeAvatarUpload() {
            this.avatarUploadLoadingDiv = util.showLoading("照片上传中");
        },
        handleAvatarError() {
            this.avatarUploadLoadingDiv.close();
            this.$message({
                message: "照片上传失败,请检查网络",
                type: "warning",
            });
        },
        onUploadSuccess(res, file, fileList) {
            this.excelImp.loading = false;
            this.excelImp.loadingDiv.close();

            if (res.code == 0) {
                this.$alert(res.msg, {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    showClose: false,
                });
            } else if (res.code == 1) {
                this.$alert(res.msg, {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                    showClose: false,
                }).then(() => {
                    // this.getDataList(1);
                    this.excelImp.dialogVisible = false;
                });
            }
        },
        onUploadError(err, file, fileList) {
            this.excelImp.loading = false;
            this.excelImp.loadingDiv.close();
            this.$alert("导入失败", {
                dangerouslyUseHTMLString: true,
                type: "warning",
            });
        },
        submitUpload() {
            this.excelImp.loading = true;
            this.excelImp.loadingDiv = util.showLoading("导入中");
            this.$refs.upload.submit();
        },
        handleExcelImp() {
            this.excelImp.fileList = [];
            this.excelImp.dialogVisible = true;
        },
        submitSH(index, row) {
            this.$confirm("嘉宾姓名: [" + row.username + "] ,确认审核通过?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loadingDiv = util.showLoading("设置审核通过中");
                    shenhe({ pid: row.id, id: row.id, status: 1 })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$alert(res.msg, {
                                    dangerouslyUseHTMLString: true,
                                    type: "warning",
                                    showClose: false,
                                });
                            } else if (res.code == 1) {
                                this.$alert(res.msg, {
                                    dangerouslyUseHTMLString: true,
                                    type: "success",
                                    showClose: false,
                                }).then(() => {
                                    // this.getDataList(this.currentPage);
                                });
                            }
                            loadingDiv.close();
                        })
                        .catch((res) => {
                            loadingDiv.close();
                        });
                })
                .catch(() => { });
        },
        showEditSortBy(row, index) {
            const loadingDiv = util.showLoading("修改排序中...");
            this.editSortBy.pid = row.id;
            this.editSortBy.sortByEditValue = row.sortBy;
            this.$prompt("请输入排序", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /(^[1-9]\d*$)/,
                inputErrorMessage: "请输入正整数",
                inputValue: row.sortBy,
                closeOnClickModal: false,
            })
                .then(({ value }) => {
                    updateSortBy({
                        pid: this.editSortBy.pid,
                        id: this.editSortBy.pid,
                        sortBy: value,
                    })
                        .then((res) => {
                            loadingDiv.close();
                            if (res.code == 0) {
                                this.$alert(res.msg, {
                                    dangerouslyUseHTMLString: true,
                                    type: "warning",
                                    showClose: false,
                                });
                            } else if (res.code == 1) {
                                this.$alert(res.msg, {
                                    dangerouslyUseHTMLString: true,
                                    type: "success",
                                    showClose: false,
                                }).then((res) => {
                                    // this.getDataList(this.currentPage);
                                });
                            }
                        })
                        .catch(() => {
                            loadingDiv.close();
                            this.$alert("修改失败,请检查您的网络", {
                                dangerouslyUseHTMLString: true,
                                type: "warning",
                            });
                        });
                })
                .catch(() => {
                    loadingDiv.close();
                    // this.$message({
                    //   type: 'info',
                    //   message: '取消输入'
                    // });
                });
        },
        submitEditDL() {
            console.log("修改抵离信息");
            console.log(this.editDLForm);
            const loadingDiv = util.showLoading("修改抵离信息中");

            if (util.isNotEmpty(this.dlDate.date1) && this.dlDate.date1 > 0) {
                this.editDLForm.esArriveTime = this.dlDate.date1 / 1000;
            } else {
                this.editDLForm.esArriveTime = "";
            }

            if (util.isNotEmpty(this.dlDate.date2) && this.dlDate.date2 > 0) {
                this.editDLForm.arriveTime = this.dlDate.date2 / 1000;
            } else {
                this.editDLForm.arriveTime = "";
            }

            if (util.isNotEmpty(this.dlDate.date3) && this.dlDate.date3 > 0) {
                this.editDLForm.backTime = this.dlDate.date3 / 1000;
            } else {
                this.editDLForm.backTime = "";
            }

            updateDLInfo(this.editDLForm)
                .then((res) => {
                    if (res.code == 0) {
                        this.$alert(res.msg, {
                            dangerouslyUseHTMLString: true,
                            type: "warning",
                            showClose: false,
                        });
                    } else if (res.code == 1) {
                        this.$alert(res.msg, {
                            dangerouslyUseHTMLString: true,
                            type: "success",
                            showClose: false,
                        }).then(() => {
                            this.editDLFormVisible = false;
                            // this.getDataList(this.currentPage);
                        });
                    }
                    loadingDiv.close();
                })
                .catch((res) => {
                    loadingDiv.close();
                });
        },
        handleEditDL(index, row) {
            this.editDLForm.pid = row.id;
            this.editDLForm.id = row.id;

            this.editDLForm.username = row.username;
            this.editDLForm.phone = row.phone;
            this.editDLForm.gender = row.gender;

            // 预计抵青时间
            this.editDLForm.esArriveTime = row.esArriveTime;
            if (util.isNotEmpty(row.esArriveTime) && row.esArriveTime > 0) {
                this.dlDate.date1 = row.esArriveTime * 1000;
            }

            // 抵青时间
            this.editDLForm.arriveTime = row.arriveTime;
            if (util.isNotEmpty(row.arriveTime) && row.arriveTime > 0) {
                this.dlDate.date2 = row.arriveTime * 1000;
            }

            this.editDLForm.arriveMode = row.arriveMode; // 到达方式 '飞机', '高铁', '自驾'
            this.editDLForm.carnum = row.carnum; // 车牌号
            this.editDLForm.frequency = row.frequency; // 班次
            this.editDLForm.arriveStation = row.arriveStation; // 抵青接站口

            // 离青时间
            this.editDLForm.backTime = row.backTime;
            if (util.isNotEmpty(row.backTime) && row.backTime > 0) {
                this.dlDate.date3 = row.backTime * 1000;
            }

            this.editDLForm.backMode = row.backMode; // 离青方式
            this.editDLForm.backCarnum = row.backCarnum; // 离青车牌号
            this.editDLForm.backFrequency = row.backFrequency; // 离青班次
            this.editDLForm.lkdd = row.lkdd; // 离青送站口
            this.editDLForm.dqqy = row.qy; // 权益
            this.editDLForm.hotelId = row.hotelId; // 分配酒店
            this.editDLForm.eatHotelId = row.eatHotelId; // 分配吃饭地点
            this.editDLForm.jdNote = row.jdNote; // 酒店床位备注
            this.editDLForm.xingchengZt = row.xingchengZt; // 行程状态
            this.editDLForm.xingchengZtCurrent = row.xingchengZt; // 修改之前行程状态
            this.editDLForm.dlNote = row.dlNote; // 抵离备注

            this.editDLFormVisible = true;
        },
        getXingchengZtDisabled(label) {
            if (util.isEmpty(this.editDLForm.xingchengZtCurrent)) {
                return false;
            }

            let labelIndex = -1;
            let currentIndex = -1;
            for (let i in this.xingchengZtList) {
                if (this.xingchengZtList[i].label == label) {
                    labelIndex = i;
                }
                if (
                    this.xingchengZtList[i].label == this.editDLForm.xingchengZtCurrent
                ) {
                    currentIndex = i;
                }
            }

            if (labelIndex < currentIndex) {
                return true;
            }
            return false;
        },
        sendYYSMS() {
            let loadingDiv;
            this.$confirm("确认发送短信邀请函？", "发送确认")
                .then((res) => {
                    if ((res = "confirm")) {
                        loadingDiv = util.showLoading("发送邀请短信中");
                        return sendYYSMS({
                            pid: this.yyInfoRow.id,
                        });
                    }
                })
                .then((res) => {
                    loadingDiv.close();
                    this.$alert(res.msg);
                })
                .catch((err) => {
                    loadingDiv.close();
                    this.$alert("发送失败,请检查网络。");
                });
        },
        sendYYEmail() {
            let loadingDiv;
            this.$confirm("确认发送邀请邮件？", "发送确认")
                .then((res) => {
                    if ((res = "confirm")) {
                        loadingDiv = util.showLoading("发送邀请邮件中");
                        return sendYYEmail({
                            pid: this.yyInfoRow.id,
                        });
                    }
                })
                .then((res) => {
                    loadingDiv.close();
                    this.$alert(res.msg);
                })
                .catch((err) => {
                    loadingDiv.close();
                    this.$alert("发送失败,请检查网络。");
                });
        },
        sendYQSMSBatch() {
            let selection = this.$refs.elTable.selection;
            if (!selection || selection.length <= 0) {
                this.$alert("请选择嘉宾");
                return;
            }

            let pids = [];
            for (let i = 0; i < selection.length; i++) {
                pids.push(selection[i].id);
            }

            let loadingDiv;
            this.$confirm("确认批量发送短信邀请函？", "发送确认")
                .then((res) => {
                    if ((res = "confirm")) {
                        loadingDiv = util.showLoading("发送邀请短信中");
                        return sendYYSMSBatch({
                            pids: pids,
                        });
                    }
                })
                .then((res) => {
                    loadingDiv.close();
                    this.$alert(res.msg);
                })
                .catch((err) => {
                    loadingDiv.close();
                    this.$alert("发送失败,请检查网络。");
                });
        },
        sendYQEmailBatch() {
            let selection = this.$refs.elTable.selection;
            if (!selection || selection.length <= 0) {
                this.$alert("请选择嘉宾");
                return;
            }

            let pids = [];
            for (let i = 0; i < selection.length; i++) {
                pids.push(selection[i].id);
            }

            let loadingDiv;
            this.$confirm("确认批量发送邮件邀请函？", "发送确认")
                .then((res) => {
                    if ((res = "confirm")) {
                        loadingDiv = util.showLoading("发送邀请邮件中");
                        return sendYYEmailBatch({
                            pids: pids,
                        });
                    }
                })
                .then((res) => {
                    loadingDiv.close();
                    this.$alert(res.msg);
                })
                .catch((err) => {
                    loadingDiv.close();
                    this.$alert("发送失败,请检查网络。");
                });
        },
        saveImg() {
            let a = document.createElement("a");
            a.href = this.yyhImg;
            a.setAttribute("download", this.genYYHRow.username + "的邀请函");
            a.click();
        },
        genYYH(index, row) {
            this.yyhImg = "";
            this.genYYHRow = row;
            this.genImgTitle = "邀请函生成中";
            this.yyInfoCode = "";
            this.yyInfoSMS = "";
            this.yyInfoSendStatus = "";
            this.yyInfoRow = row;

            getInvitationCode({ p_id: row.id }).then((res) => {
                if (res.code != 1 || util.isEmpty(res.data)) {
                    this.$alert(res.msg, {
                        dangerouslyUseHTMLString: true,
                        type: "warning",
                    });
                    return;
                }

                this.yyInfoCode = res.data.code;
                this.XCXScanTime = res.data.XCXScanTime;
                this.H5ScanTime = res.data.H5ScanTime;

                /*
                【青岛影视博览会】尊敬的苏厚栋，诚邀您参加·2023青岛影视博览会，访问 https://oa.tonggao.info/CN/Customer/Login?projectCode=4a67d0c86af1f94 可以反馈您的参会行程，期待您的莅临。
                * */
                this.yyInfoSMS =
                    "【青岛影视博览会】尊敬的" +
                    row.username +
                    "，诚邀您参加·2023青岛影视博览会，访问 y.qdysblh.com/#/?code=" +
                    this.yyInfoCode +
                    " 可以反馈您的参会行程，期待您的莅临。";

                this.painting.views = [
                    {
                        type: "image",
                        url: "https://cdn.ybh.gengduoke.com/zuizhongban.jpg",
                        css: {
                            width: `1080px`,
                            height: `1890px`,
                            top: "0",
                        },
                    },
                    {
                        type: "qrcode",
                        content: "https://y.qdysblh.com/" + this.yyInfoCode,
                        css: {
                            top: `1330px`,
                            left: `385px`,
                            color: "#000",
                            width: `320px`,
                            height: `320px`,
                            borderColor: "#FFF",
                            borderWidth: "15px",
                            background: "#FFF",
                        },
                    },
                    {
                        type: "text",
                        text: row.username,
                        css: {
                            top: `630px`,
                            left: `215px`,
                            color: "#FFF",
                            fontSize: "37px",
                        },
                    },
                ];

                this.genPoster = true;
                this.yyhDialogVisible = true;
            });
        },
        genYYHSuccess(val) {
            this.genImgTitle = "邀请函生成成功";
            this.genPoster = false;
            this.yyhImg = val;
            if (this.genYYHLoadingDiv) {
                this.genYYHLoadingDiv.close();
            }
        },
        genYYHFail(val) {
            this.genImgTitle = "";
            this.genPoster = false;
            this.$alert("生成邀请函失败");
            this.yyhDialogVisible = false;
        },
        handleCheckAllChange(val) {
            if (val) {
                let ids = [];
                for (let i in this.plateList) {
                    ids.push(this.plateList[i].id);
                }
                this.editForm.hyIds = ids;
            } else {
                this.editForm.hyIds = [];
            }
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.plateList.length - 1;
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.plateList.length - 1;
        },
        handleAddressChange() {
            if (this.selectValue.length == 2) {
                this.editForm.sheng = this.selectValue[0];
                this.editForm.shi = this.selectValue[0];
                this.editForm.xian = this.selectValue[1];
            } else {
                this.editForm.sheng = this.selectValue[0];
                this.editForm.shi = this.selectValue[1];
                this.editForm.xian = this.selectValue[2];
            }
        },
        clear() {
            for (let key in this.searchForm) {
                this.searchForm[key] = "";
            }
            for (let key in this.date) {
                this.date[key] = "";
            }
            this.searchForm.userTypeIdList = [1, 2, 3, 4, 5, 6];
            // this.getDataList(1);
        },
        init() {
            getUserTypeList().then((res) => {
                if (res.code) {
                    let userTypeList = [];
                    userTypeList.push({ name: "请选择嘉宾类型", id: "" });
                    // this.userTypeList = res.data;
                    // this.userTypeList.unshift({name: "请选择嘉宾类型", id:''})
                    for (let i in res.data) {
                        if (
                            res.data[i].id == 1 ||
                            res.data[i].id == 2 ||
                            res.data[i].id == 3 ||
                            res.data[i].id == 4 ||
                            res.data[i].id == 5 ||
                            res.data[i].id == 6
                        ) {
                            userTypeList.push(res.data[i]);
                        }
                    }

                    this.userTypeList = userTypeList;
                }
            });

            // getPlateList().then(res=>{
            //   if (res.code){
            //     this.plateList = res.data;
            //     this.plateList.unshift({title: "请选择会议", id:''})
            //   }
            // });
            // getYycompanyList().then(res=>{
            //   if (res.code){
            //     this.yyCompanyList = res.data;
            //     this.yyCompanyList.unshift({name:"请选择邀宾单位/部门", id: ''});
            //
            //   }
            // });

            getSystemSet({ group: "work_group" }).then((res) => {
                if (res.code) {
                    // this.yyCompanyList = res.data;
                    this.workGroupList.unshift({ title: "请选择部门/组", value: "" });
                    for (let i in res.data) {
                        this.workGroupList.push({
                            title: res.data[i].title,
                            value: res.data[i].title,
                        });
                    }
                }
            });

            getHotelList().then((res) => {
                if (res.code) {
                    this.hotelList = res.data;
                    this.hotelList.unshift({ id: "", name: "请选择酒店" });
                }
            });

            getAllUserList().then((res) => {
                if (res.code) {
                    this.userList = JSON.parse(JSON.stringify(res.data));
                    this.handlerUserList = res.data;

                    this.userList.unshift({ id: "", nickname: "请选择管理员" });
                    this.handlerUserList.unshift({ id: "", nickname: "请选择联络员" });
                }
            });

            // 按照分组获取会议
            getPlateListWithGroup({ isZy: 0 }).then((res) => {
                this.plateWithGroupList = res.data;
                if (res.code) {
                    this.tabsActiveName = res.data[0].title;
                }
            });
        },
        parsePlate(row, status) {
            if (util.isNotEmpty(row.plateList)) {
                let str = "";
                let index = 1;
                let plateList = row.plateList;
                for (let i in plateList) {
                    if (util.isEmpty(plateList[i])) {
                        continue;
                    }
                    str +=
                        "<div class='ellipsis'>" +
                        index +
                        ".<" +
                        plateList[i].title +
                        "></div>";
                    index++;
                }

                if (status == 1) {
                    if (row.status == 1 || row.status == 3) {
                        return str;
                    }
                } else if (status == 0) {
                    if (row.status == 2) {
                        return str;
                    }
                }
            } else {
                return "";
            }
        },

        fmtDate2(d2, fmt) {
            // yyyy-MM-dd hh:mm:ss
            if (util.isEmpty(d2) || d2 <= 0) {
                return "";
            }
            return this.fmtDate(new Date(d2 * 1000), fmt);
        },
        fmtDate(d, fmt) {
            // yyyy-MM-dd hh:mm:ss
            if (!fmt) {
                fmt = "yyyy-MM-dd hh:mm:ss";
            }
            var o = {
                "M+": d.getMonth() + 1, //月份
                "d+": d.getDate(), //日
                "h+": d.getHours(), //小时
                "m+": d.getMinutes(), //分
                "s+": d.getSeconds(), //秒
                "q+": Math.floor((d.getMonth() + 3) / 3), //季度
                S: d.getMilliseconds(), //毫秒
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    (d.getFullYear() + "").substr(4 - RegExp.$1.length)
                );
            }
            for (var k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1
                            ? o[k]
                            : ("00" + o[k]).substr(("" + o[k]).length)
                    );
                }
            }
            return fmt;
        },
        showAddDialogForm() {
            this.getParticipateGB();

            if (this.$refs.editForm) {
                this.$refs.editForm.clearValidate();
            }

            if (this.frontUserList && this.frontUserList.length <= 0) {
                getFrontUserList().then((res) => {
                    if (res.code) {
                        this.frontUserList = res.data;
                    }
                });
            }

            for (let key in this.editForm) {
                this.editForm[key] = "";
            }
            this.addShowFlag = false;
            this.editForm.id = 0;
            this.editForm.country = "中国";
            this.editForm.idCardType = 0;
            this.editForm.hyIds = [];
            this.selectValue = "";
            this.tabsActiveName = this.plateWithGroupList[0].title;

            this.editFormVisible = true;
        },
        handleEdit(index, row) {
            this.getParticipateGB();

            if (this.$refs.editForm) {
                this.$refs.editForm.clearValidate();
            }

            if (this.frontUserList && this.frontUserList.length <= 0) {
                getFrontUserList().then((res) => {
                    if (res.code) {
                        this.frontUserList = res.data;
                    }
                });
            }

            this.addShowFlag = true;
            this.editForm.id = row.id;
            this.editForm.frontUserId = row.frontUserId;
            this.editForm.handlerId = row.handlerId;
            this.editForm.username = row.username;
            this.editForm.phone = row.phone;
            this.editForm.gender = row.gender;
            this.editForm.company = row.company;
            this.editForm.post = row.post;
            this.editForm.yslingdao = row.yslingdao;
            this.editForm.ysdesc = row.ysdesc;
            this.editForm.country = row.country;
            this.editForm.idcard = row.idcard;
            this.editForm.idCardType = row.idCardType;
            this.editForm.sheng = row.sheng;
            this.editForm.shi = row.shi;
            this.editForm.xian = row.xian;
            this.editForm.email = row.email;
            this.editForm.phoneType = row.phoneType;
            this.editForm.faceImg = row.faceImg;
            this.editForm.ppid = row.ppid;
            this.editForm.qyOther = row.qyOther;
            this.editForm.note2 = row.note2;

            if (row.sheng == row.shi) {
                this.selectValue = [row.sheng, row.xian];
            } else {
                this.selectValue = [row.sheng, row.shi, row.xian];
            }

            let hyIds = [];
            if (row.plateList && row.plateList.length > 0) {
                for (let i in row.plateList) {
                    hyIds.push(row.plateList[i].plateId);
                }
            }
            this.editForm.hyIds = hyIds;
            this.editForm.userTypeId = row.userTypeId;
            this.editForm.yyCompanyId = row.yyCompanyId;
            this.editForm.workGroup = row.workGroup;
            this.editForm.qy = row.qy;
            this.editForm.note = row.note;
            this.editForm.status = row.status;
            this.editForm.yq = row.yq;
            this.editForm.yyType = row.yyType;
            this.tabsActiveName = this.plateWithGroupList[0].title;

            this.editFormVisible = true;
        },
        submitForm() {
            if (this.editForm.userTypeId == 6 && util.isEmpty(this.editForm.ppid)) {
                this.$message.error("请选择贵宾");
                return;
            }

            if (this.editForm.userTypeId != 6) {
                this.editForm.ppid = "";
            }

            if (this.editForm.status == 3) {
                if (util.isEmpty(this.editForm.idcard)) {
                    this.$message.error("请填写证件号码");
                    return;
                }
            }

            if (this.editForm.status == 3) {
                if (util.isEmpty(this.editForm.phone)) {
                    this.$message.error("请填写手机号码");
                    return;
                }
            }

            if (util.isNotEmpty(this.editForm.phone)) {
                if (util.isEmpty(this.editForm.phoneType)) {
                    this.$message.error("请选择电话类型");
                    return;
                }
            }

            this.$refs.editForm.validate((valid, val) => {
                if (!valid) {
                    let msg = "";
                    for (let key in val) {
                        msg = val[key][0].message;
                        break;
                    }
                    this.$message.error(msg);
                    return;
                }

                if (this.editForm.qy == "其他") {
                    if (util.isEmpty(this.editForm.qyOther)) {
                        this.$message.error("请填写付费方式");
                        return;
                    }
                } else {
                    this.editForm.qyOther == "";
                }

                if (valid) {
                    this.editForm.hyId = this.editForm.hyIds.join(",");

                    this.loading = true;
                    let loadingText = "添加中...";
                    if (this.editForm.id) {
                        loadingText = "修改中...";
                    }
                    const loadingDiv = util.showLoading(loadingText);
                    let func = updateMyParticipate;
                    if (this.editForm.id == 0) {
                        func = addMyParticipate;
                    }
                    func(this.editForm)
                        .then((res) => {
                            let title = "修改";
                            if (this.editForm.id == 0) {
                                title = "添加";
                            }
                            if (res.code == 0) {
                                this.$alert(res.msg, title + "失败", {
                                    dangerouslyUseHTMLString: true,
                                    type: "warning",
                                    showClose: false,
                                });
                            } else if (res.code == 1) {
                                this.$alert(res.msg, title + "成功", {
                                    dangerouslyUseHTMLString: true,
                                    type: "success",
                                    showClose: false,
                                }).then(() => {
                                    let pNum = 1;
                                    this.editFormVisible = false;
                                    if (this.editForm.id > 0) {
                                        pNum = this.currentPage;
                                    }
                                    // this.getDataList(pNum);
                                });
                            }
                            this.loading = false;
                            loadingDiv.close();
                        })
                        .catch((res) => {
                            this.loading = false;
                            loadingDiv.close();
                        });
                }
            });
        },
        handleDelete(index, row) {
            let loadingDiv;
            this.$prompt("请输入删除原因", "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(({ value }) => {
                    if (util.isEmpty(value)) {
                        this.$message.error("请填写删除原因");
                        this.handleDelete(index, row);
                        return;
                    } else {
                        let loadingDiv = util.showLoading("删除中...");
                        deleteMyParticipate({ pid: row.id, deleteReason: value })
                            .then((res) => {
                                loadingDiv.close();
                                // console.log("===================")
                                // console.log(res)
                                if (res.code == 0) {
                                    this.$alert(res.msg, "删除失败", {
                                        dangerouslyUseHTMLString: true,
                                        type: "warning",
                                        showClose: false,
                                    });
                                } else if (res.code == 1) {
                                    this.$alert(res.msg, "删除成功", {
                                        dangerouslyUseHTMLString: true,
                                        type: "success",
                                        showClose: false,
                                    }).then((res) => {
                                        this.editFormVisible = false;
                                        // this.getDataList(this.currentPage);
                                    });
                                }
                            })
                            .catch((res) => {
                                if (loadingDiv) {
                                    loadingDiv.close();
                                }
                            });
                    }
                })
                .catch((res) => {
                    // if (loadingDiv){
                    //   loadingDiv.close();
                    // }
                });
        },

        // getDataList(pageNum) {
        //     const loadingDiv = util.showLoading();

        //     let param = {
        //         pageSize: this.pageSize,
        //         pageNum: pageNum,
        //     };

        //     if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        //         this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        //         this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
        //     } else {
        //         this.searchForm.arriveTime1 = "";
        //         this.searchForm.arriveTime2 = "";
        //     }

        //     if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        //         this.searchForm.backTime1 = this.date.date2[0] / 1000;
        //         this.searchForm.backTime2 = this.date.date2[1] / 1000;
        //     } else {
        //         this.searchForm.backTime1 = "";
        //         this.searchForm.backTime2 = "";
        //     }

        //     if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        //         this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        //         this.searchForm.updateTime2 = this.date.date3[1] / 1000;
        //     } else {
        //         this.searchForm.updateTime1 = "";
        //         this.searchForm.updateTime2 = "";
        //     }

        //     if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        //         this.searchForm.createTime1 = this.date.date4[0] / 1000;
        //         this.searchForm.createTime2 = this.date.date4[1] / 1000;
        //     } else {
        //         this.searchForm.createTime1 = "";
        //         this.searchForm.createTime2 = "";
        //     }

        //     for (var i in this.searchForm) {
        //         param[i] = this.searchForm[i];
        //     }

        //     // getMyParticipateList(param)
        //     //     .then((res) => {
        //     //         if (res.code == 0) {
        //     //             this.$message({
        //     //                 showClose: true,
        //     //                 message: res.msg,
        //     //                 type: "error",
        //     //             });
        //     //         } else if (res.code == 1) {
        //     //             let data = res.data;
        //     //             this.plateList = data.plateList;
        //     //             this.plateList.unshift({ title: "请选择会议", id: "" });
        //     //             this.currentPage = pageNum;
        //     //             this.total = data.total;
        //     //             this.tableData = data.dataList;
        //     //             if (data.plateList) {
        //     //                 this.plateList = data.plateList;
        //     //             }

        //     //             if (util.isNotEmpty(data.yyCompanyName)) {
        //     //                 this.yyCompanyName = data.yyCompanyName;
        //     //             }
        //     //         }
        //     //         loadingDiv.close();
        //     //     })
        //     //     .catch((res) => {
        //     //         loadingDiv.close();
        //     //     });
        // },
        search() {
            // this.getDataList(1);
        },
        handleCurrentChange(pageNum) {
            // this.getDataList(pageNum);
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            // this.getDataList(1);
        },
        exportExcel() {
            const loadingDiv = util.showLoading("正在导出EXCEL");

            let param = {};

            if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
                this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
                this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
            }

            if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
                this.searchForm.backTime1 = this.date.date2[0] / 1000;
                this.searchForm.backTime2 = this.date.date2[1] / 1000;
            }

            if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
                this.searchForm.updateTime1 = this.date.date3[0] / 1000;
                this.searchForm.updateTime2 = this.date.date3[1] / 1000;
            }

            if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
                this.searchForm.createTime1 = this.date.date4[0] / 1000;
                this.searchForm.createTime2 = this.date.date4[1] / 1000;
            }

            for (var i in this.searchForm) {
                param[i] = this.searchForm[i];
            }

            let t = getToken();
            let headers = { "x-token": t };
            axios({
                method: "post",
                url: this.$adminApiUrl + "/participate/export-my-excel", // 请求地址
                data: param, // 参数
                headers: headers,

                responseType: "blob", // 表明返回服务器返回的数据类型
            })
                .then((res) => {
                    loadingDiv.close();
                    console.log(res);
                    let blob = new Blob([res.data], { type: "application/xlsx" });
                    let url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a"); // 创建a标签
                    link.href = url;
                    let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");
                    link.download = "按搜索条件导出嘉宾_" + timeStr + ".xlsx"; // 重命名文件
                    link.click();
                    URL.revokeObjectURL(url);
                })
                .catch((err) => {
                    console.log(err);
                    loadingDiv.close();
                    this.$alert("导出失败,请检查网络", {
                        dangerouslyUseHTMLString: true,
                        type: "warning",
                    });
                });
        },
        exportExcelNew(num) {
            const loadingDiv = util.showLoading("正在导出EXCEL");

            let param = { num: num };
            let t = getToken();
            let headers = { "x-token": t };
            axios({
                method: "post",
                url: this.$adminApiUrl + "/participate/export-excel-new", // 请求地址
                data: param, // 参数
                headers: headers,

                responseType: "blob", // 表明返回服务器返回的数据类型
            })
                .then((res) => {
                    loadingDiv.close();
                    let blob = new Blob([res.data], { type: "application/xlsx" });
                    let url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a"); // 创建a标签
                    link.href = url;
                    let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

                    let fileName = "2023青岛影视博览会嘉宾信息表";
                    switch (num) {
                        case 10:
                            fileName = "2023青岛影视博览会嘉宾信息表";
                            break;
                        case 11:
                            fileName = "2023青岛影视博览会政务贵宾信息表";
                            break;
                        case 12:
                            fileName = "2023青岛影视博览会政务嘉宾信息表";
                            break;
                        case 13:
                            fileName = "2023青岛影视博览会行业贵宾信息表";
                            break;
                        case 14:
                            fileName = "2023青岛影视博览会行业嘉宾信息表";
                            break;
                    }

                    link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
                    link.click();
                    URL.revokeObjectURL(url);
                })
                .catch((err) => {
                    console.log(err);
                    loadingDiv.close();
                    this.$alert("导出失败,请检查网络", {
                        dangerouslyUseHTMLString: true,
                        type: "warning",
                    });
                });
        },
    },
};
</script>
  
<style scoped lang="scss">

html, body {
    font-family: sans-serif;
    height: 100%;
    idth: 100%;
 }
 .container{
    display: flex; // 关键， flex布局
    flex-direction: column; // 关键，三块主题column摆放。
    height: 400px; // 关键，设置高度为可见高度的100%；        
 }
 .head{ 
    width: 100%;
 }
 .body{
    flex: 1; // 关键， 填充剩余空间
    width: 100%;
    overflow-y: scroll;   // 关键， 超出部分滚动
 }
 .footer{
    width: 100%;
 }

.xcxc {
    width: 200px;
    height: 200px;
    position: relative !important; 
    margin-right: 10px;
    margin-top: 10px;
}
.zzzz {
    width: 100%;
    height: 100%;
    position: relative !important;
}
.xcxc:hover .cccc{
    display: flex;
}
.cccc {
    display: none;
    position: absolute; 
    top: 0;
    width: 100%;
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5);
}
// @import "~@/common/styles/element-variables.scss";
.search-box {
    background-color: #ffffff;
    min-height: 36px;
    line-height: 36px;
    padding-top: 2px;
    padding-bottom: 6px;

    .el-form-item {
        margin-bottom: 0px !important;
    }

    .el-form--inline {
        display: inline-block;
    }
}

.pagination-box {
    text-align: left;
    margin-top: 10px;
}
</style>
<style lang="scss">
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.el-dialog__header {}

.el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0px;
}

.el-dialog__footer {
    padding-top: 0px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.el-tabs--border-card {
    box-shadow: none;
}
</style>
  