import request from '@/common/utils/request'
import Vue from "vue";

export function shangchuan(data) {
    return request({
      url: Vue.prototype.$adminApiUrl + '/imagelibrary2022/list',
      method: 'post',
      data
    })
  }
  export function chakan(data) {
    return request({
      url: Vue.prototype.$adminApiUrl + '/imagelibrary2022/info/'+ data,
      method: 'get',
     
    })
  }
  export function delt(data) {
    return request({
      url: Vue.prototype.$adminApiUrl + '/imagelibrary2022/delete/'+ data,
      method: 'get',
     
    })
  }
  export function moban(data) {
    return request({
      url: Vue.prototype.$adminApiUrl + '/imagelibrary2022/select',
      method: 'get',
     
    })
  }

  export function juese(data) {
    return request({
      url: Vue.prototype.$baseUrl + '/select/role',
      method: 'get',
     
    })
  }

  export function tok(data) {
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/token',
      method: 'get',
     
    })
  }

 